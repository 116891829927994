<template>
<div>
    <!-- 通道管理》通道公司》增加或修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="138px" style="margin-bottom: 12px;">
                    <el-form-item label="公司名称">
                        <el-input v-model="form_data.company_name" placeholder="请输入公司名称" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="开户行">
                        <el-input v-model="form_data.bank" placeholder="请输入开户行" size="mini"></el-input>
                    </el-form-item>
                    <el-form-item label="开户行账号">
                        <el-input v-model="form_data.bank_number" placeholder="请输入开户行账号" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="预警app">
                        <el-radio-group v-model="form_data.app_name" size="mini">
                            <el-radio label="-1">不配置</el-radio>
                            <el-radio label="1">微信</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-show="form_data.app_name=='1'" label="预警群名称">
                        <el-input v-model="form_data.crowds_name" placeholder="请输入预警群名称" size="mini"></el-input>
                    </el-form-item>


                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-row>
    </el-dialog>


</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
           //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                if(this.prop_item.type === "upd"){//修改
                    this.cur_title = "通道签约公司的修改";
                    setTimeout(() => {

                        //获取数据
                        this.getData();
                        
                    }, 1);
                }else{
                    this.cur_title = "道签约公司的增加";
                    setTimeout(() => {

                    }, 1);
                }
            }
            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见


            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头


            form_data:{
                company_name:"",//公司名称
                bank:"",//开户行
                bank_number:"",//开户行账号
                app_name:"1",//预警app -1：无  1:微信
                crowds_name:"",//预警群名称
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //查询
        getData(){
            this.form_data.company_id = this.prop_item.company_id;//公司id
            this.form_data.company_name = this.prop_item.company_name;//公司名称
            this.form_data.bank = this.prop_item.bank;//开户行
            this.form_data.bank_number = this.prop_item.bank_number,//开户行账号
            this.form_data.app_name = this.prop_item.app_name+"",//预警app -1：无  1:微信
            this.form_data.crowds_name = this.prop_item.crowds_name;//预警群名称

        },
        

        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.company_name)){
                this.$message.error("请填写公司名称");
                return;
            }
            if(API.isEmtry(this.form_data.bank)){
                this.$message.error("请填写开户行");
                return;
            }
            if(API.isEmtry(this.form_data.bank_number)){
                this.$message.error("请填写开户行账号");
                return;
            }
            if(API.isEmtry(this.form_data.app_name == "1")){
                if(API.isEmtry(this.form_data.crowds_name)){
                    this.$message.error("请填写预警群名称");
                    return;
                }
            }

            var submit_obj = {};
            if(this.prop_item.type === "upd"){//修改
                submit_obj.param = "upd";
                submit_obj.company_id = this.form_data.company_id;//通道公司id
            }else{//增加
                submit_obj.param = "add";
            }
            submit_obj.company_name = this.form_data.company_name;//
            submit_obj.bank = this.form_data.bank;//
            submit_obj.bank_number = this.form_data.bank_number;//
            submit_obj.app_name = this.form_data.app_name;//
            submit_obj.crowds_name = this.form_data.crowds_name;//
            
            
            API.ChannelCompanyServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },



        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 1px;
} 

</style>