<template>
    <!-- 通道管理》通道公司 -->
    <div id="app_channel_company_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>

            <div class="head_top_title">通道公司</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" >


                    <el-form-item label="公司名称">
                        <el-input v-model="form_data.search" placeholder="默认全部" size="mini"></el-input>
                    </el-form-item>
                    

                    <el-form-item>
                        <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                    </el-form-item>

                </el-form>
                
                
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  prop="company_name" label="公司名" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="bank" label="开户行" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="bank_number" label="开户行账号" min-width="80"  align="center"> </el-table-column>
                    
                    <el-table-column label="预警app" min-width="80"  align="center"> 
                        <template slot-scope="scope">
                            {{scope.row.app_name == 1 ? '微信' : '未配置'}}
                        </template>
                    </el-table-column>
                    <el-table-column  prop="crowds_name" label="预警群名称" min-width="80"  align="center"> </el-table-column>

                    <el-table-column   label="操作"  min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="upd(scope.row)">修改</el-button>
                            <el-button type="text" size="mini" style="color:#f56c6c"  @click="del(scope.row.company_id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>


        </el-row>

        
        

    <!-- 通道公司增加h或修改组件 -->
    <ChannelCompanyAddUpd :prop_change_i="prop_channel_company_add_upd_i" :prop_item="prop_channel_company_add_upd_item"
        @result="getData"
      ></ChannelCompanyAddUpd>


    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import ChannelCompanyAddUpd from './channel_company_add_upd.vue';//组件 通道公司增加h或修改

//import axios from "axios";
export default {

    components: {
        ChannelCompanyAddUpd,
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度


            form_data:{
                search:"",//查询条件-通道名
            },

            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            query_condition_height:40,//查询条件的高度


            //通道公司增加h或修改
            prop_channel_company_add_upd_i:0,//通道公司增加h或修改  组件 弹出框是否可见
            prop_channel_company_add_upd_item:{},//通道公司增加h或修改  组件 参数

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.form_data = this.$options.data().form_data;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

        },

        //增加
        add(){
            console.log("增加");
            this.prop_channel_company_add_upd_item.type = "add";
            this.prop_channel_company_add_upd_i ++;//弹出框是否可见
        },
        //修改
        upd(item){
            console.log("修改");
            this.prop_channel_company_add_upd_item = item;
            this.prop_channel_company_add_upd_item.type = "upd";
            this.prop_channel_company_add_upd_i ++;//弹出框是否可见
        },


        //查询
        getData(){

            //请求接口
            API.ChannelCompanyServlet({
                param: "list",
                search:this.form_data.search,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
       

        //删除
        del(id){
            this.$confirm('此操作将永久删除通道签约公司, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.ChannelCompanyServlet({
                        param: "del",
                        company_id:id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，通道签约公司删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },
        

        //返回
        goBack() {
            API.router_to("/channel_index");
        }
    }
};

</script>

<style scoped>



/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}

</style>